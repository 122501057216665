const _roundDecimal = (type: 'floor' | 'round' | 'ceil', number: number, decimals: number = 0) => {
  return Math[type](number * 10 ** decimals) / 10 ** decimals;
};

export const floorDecimals = (number: number, decimals: number = 0) => {
  return _roundDecimal('floor', number, decimals);
};

const SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P'];
export const abbreviateNumber = (
  number: number,
  decimals: number = 0,
  isInteger: boolean = false,
) => {
  if (isInteger && number < 1000) return number.toFixed(0);

  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number.toFixed(decimals);

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(decimals) + suffix;
};

/**
 * Checks if the amount is "null" (zero).
 *
 * @param numeric - The amount string to check.
 */
export const isNullAmount = (numeric: number): boolean =>
  numeric <= 0 || Number.isNaN(numeric) || !Number.isFinite(numeric);

export const formatTimeElapsed = (timestamp: number): string => {
  const now = Date.now();

  const timestampMs = timestamp * 1000;

  const diffMs = now - timestampMs;

  // Convert the time elapsed into various units
  const seconds = Math.floor(diffMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30.42); // Average month length
  const years = Math.floor(months / 12);

  if (years >= 1) return years === 1 ? '1 year' : `${years} years`;

  if (months >= 1) return months === 1 ? '1 month' : `${months} months`;

  if (days >= 1) return days === 1 ? '1 day' : `${days} days`;

  if (hours >= 1) return hours === 1 ? '1 hour' : `${hours} hours`;

  if (minutes >= 1) return minutes === 1 ? '1 minute' : `${minutes} minutes`;

  return seconds <= 1 ? '1 second' : `${seconds} seconds`;
};
